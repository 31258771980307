import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from './../_helper/default';
import {
  API_INSERT_UPDATE_MASTER_DATA,
  API_GET_PLANTS,
  API_GET_USER_PLANTS,
  API_GET_COLLECTION_TYPES,
  API_GET_MINE_LIST,
  API_GET_MASTER_DATA_LIST,
  API_GET_MASTER_DATA_DELETE,
  API_GET_TRANSPORTER_LIST,
  API_GET_DEPT_LIST,
  API_GET_DESIGNATION_LIST,
  API_GET_PLANT_MINE_LIST,
  API_GET_FEEDER_LIST,
  GET_ALL_SAMPLE_PART_SIZE,
  API_GET_AUGER_BY_PLANT,
  GET_ALL_USER_CATEGORY,
  GET_ALL_USER_LIST,
  GET_ALL_MINE_BY_COLLECTION_TYPE,
  API_GET_ALL_PLANT_MINE_LIST,
  API_GET_TRANSPORTER_LISTING,
  API_POST_TRANSPORTER_INSERT,
  API_GET_TRANSPORTER_DELETE,
  GET_TYPE_LIST,
  INSERT_TYPE_MASTER,
  API_GET_ALL_PLANT_MINES,
  API_GET_ALL_DO_LIST,
  API_GET_STATUS_CHANGE,
  API_POST_DO_SAVE,
  API_DO_COLLECTION_TYPES,
  API_ALL_MINES_MASTER,
  API_GET_DO_LIST,
  API_GET_PLANT_MINE_DELETE,
  API_GET_LOCATION_LIST,
  API_INSERT_UPDATE_LOCATION_DATA,
  API_GET_CUSTOMERS,
  API_GET_RFID_MASTER_LIST,
  API_GET_ALL_SAMPLING_POINT,
  API_GET_USER_WISE_MINE_PLANT_MAP,
  API_GET_ALL_GRADE,
  API_GET_SIDING_SILO,
  API_GET_RECEIVING_CUSTOMERS,
  API_GET_USER_WISE_MINE_MAP,
  GET_ALL_GRADE,
  GET_USER_WISE_MINE_MAP,
  API_GET_SIDING_SOURCE,
  API_GET_COMPLETED_TEST_RESULT,
  API_GET_REFEREE_RESULT,
  API_GET_REFFEREE_LISTING,
  API_GET_TEST_RESULT_FOR_SUBMIT,
  API_SUBMIT_LAB_TEST_RESULT,
  API_UPDATE_LAB_TEST_RESULT,
  API_UPDATE_PAYMENT_DETAILS,
  API_UPDATE_REFEREE_RESULT,
  API_DOWNLOAD_REFEREE_DOC,
  API_GET_THIRD_PARTY_LAB_LISTING,
  API_GET_ALL_THIRD_PARTY_LAB,
  API_SEND_TO_THIRD_PARTY_LAB,
  API_GET_REFREE_UPLOAD_LISTING,
  API_GENERATE_SAMPLE_REPORT,
  API_DOWNLOAD_TEST_REPORT,
  API_DOWNLOAD_GCV_REPORT,
  API_GET_AREA_LIST,
  API_GET_AREA_TABLE_LISTING,
  API_GET_ADD_AREA_LIST,
  API_GET_USER_PLANTS_UNLOADING,
  API_GET_RECEIVING_CUSTOMERS_UNLOADING,
  API_GET_USER_WISE_MINE_MAP_UNLOADING,
  API_GET_USER_WISE_MINE_PLANT_MAP_UNLOADING,
  API_GET_SIDING_TREE,
  API_GET_SIDING_TABLE_LIST,
  API_GET_ADD_SIDING,
  API_GET_SIDING_FOR_UPDATE,
  API_GET_CONSUMER_LIST,
  API_GET_CONSUMER_TREE,
  API_GET_MGR_LOADING, API_GET_AREA,
  API_GET_ALL_SIDING_MASTER,
  API_GET_CONSUMER_TREE_NODE,
  API_GET_ALL_CATEGORY_MASTER,
  API_INSERT_UPDATE_CONSUMER_SUBSIDARY,
  API_GET_CUSTOMER_DETAILS_BYID,
  API_GET_ALL_CUSTOMER_NAME_LIST,
  API_INSERT_UPDATE_USER_MASTER,
  API_ADD_SUBSIDIARY,
  API_GET_SUBSIDIARY_LIST,
  API_GET_SUBSIDIARY_PRODUCER,
  API_GET_PRODUCER_LIST,
  API_ADD_PRODUCER,
  API_GET_SOURCE_TREENODE,
  API_GET_SOURCE,
  API_INSER_UPDATE_SOURCE_MASTER,
  API_GET_ALL_SOURCE_MASTER_LIST_EDIT_BYID,
  GET_USER_WISE_MINE_MAP_RESULT_SUBMIT,
  API_UPDATE_OTHER_INFO,
  API_GET_ALL_NOTIFICATION_DETAILS,
  API_GET_ALL_SUBSIDIARY,
  API_GET_ALL_IGI_REPORT_FOR_REJECTION_CHALLENGE,
  API_UPDATE_NOTIFICATION

} from '../_common/constant';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(
    private helper: Helper,
    private http: HttpClient) { }

  // THIS FUNCTION IS USED TO SAVE / UPDATE ALL MASTER DATA //
  _saveMstData(mstData: any) {
    return this.http.post(API_INSERT_UPDATE_MASTER_DATA, mstData);
  }

  // FETCH MASTER DATA //
  _getMstList(queryParam: string) {
    return this.http.get(API_GET_MASTER_DATA_LIST + queryParam);
  }
  // DELETE MASTER DATA //
  _getMstDelete(queryParam: string) {
    return this.http.get(API_GET_MASTER_DATA_DELETE + queryParam);
  }

  // ALL PLANT LIST //
  _getAllPlant() {
    return this.http.get(API_GET_PLANTS);
  }

  // ALL MINE LIST UNDER PLANT //
  _getAllPlantMine(collectionTypeID: number) {
    return this.http.get(API_GET_PLANT_MINE_LIST + '?PlantID=' + 0 + '&CollectionTypeID=' + collectionTypeID);
  }

  // ALL MINE BY COLLECTION TYPE //
  _getAllMineByCollectionType(collectionTypeID: number) {
    return this.http.get(GET_ALL_MINE_BY_COLLECTION_TYPE + '?PlantID=' + 0 + '&CollectionTypeID=' + collectionTypeID);
  }

  // ALL MINE BY COLLECTION TYPE //
  _GetAllDoByPlantMineCollType(queryParam: string) {
    let url = API_GET_DO_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  _getAllPlantMineList(queryParam: string) {
    return this.http.get(API_GET_ALL_PLANT_MINE_LIST + queryParam);
  }


  // ALL FEEDER LIST UNDER PLANT //
  _getAllFeeder() {
    return this.http.get(`${API_GET_FEEDER_LIST}?PlantID=${0}`);
  }

  // ALL DEPARTMENT LIST //
  _getDeptList() {
    return this.http.get(API_GET_DEPT_LIST);
  }

  // ALL DESIGNATION LIST //
  _getDesignationList() {
    return this.http.get(API_GET_DESIGNATION_LIST);
  }

  // USER WISE PLANT LIST //
  _getPlantByUser() {
    return this.http.get(API_GET_USER_PLANTS);
  }

  _getPlantByUserUnloading() {
    return this.http.get(API_GET_USER_PLANTS_UNLOADING);
  }


  // COLLECTION TYPE LIST //
  _getAllCollectionType(queryParam?: string) {
    let url = API_GET_COLLECTION_TYPES;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  // MINE LIST //
  _getAllMine(queryParam?: string) {
    let url = API_GET_MINE_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  // GET ALL MINE //
  getAllMine() {
    return this.http.get(`${API_GET_MINE_LIST}?PlantID=${0}`);
  }

  // ALL TRANSPORTER LIST //
  _getAllTransporter(queryParam?: string) {
    let url = API_GET_TRANSPORTER_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  // TRANSPORTER LIST BY PLANT //
  _getTransporterByPlant() {
    return this.http.get(`${API_GET_TRANSPORTER_LIST}?PlantID=${0}`);
  }

  // SAMPLE SIZE LIST //
  _sampleSizeList(toBeTested: number) {
    return this.http.get(`${GET_ALL_SAMPLE_PART_SIZE}?ToBeTested=${toBeTested}`);
  }

  // AUGER LIST BY PLANT //
  _getAugerByPlant() {
    return this.http.get(`${API_GET_AUGER_BY_PLANT}?PlantID=${0}`);
  }

  // USER CATEGORY LIST //
  _getUserCategory() {
    return this.http.get(`${GET_ALL_USER_CATEGORY}`);
  }

  // ALL USER LIST BY CATEGORY //
  _getAllUserList() {
    return this.http.get(`${GET_ALL_USER_LIST}?UserCategory=${this.helper.getUserCategory()}`);
  }

  /* For New Version */
  /* Transporter Insert and Update */
  _getTransporterListing(queryParam?: string) {
    let url = API_GET_TRANSPORTER_LISTING;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  /* Transporter Listing */
  _saveTransporterMstData(mstData: any) {
    return this.http.post(API_POST_TRANSPORTER_INSERT, mstData);
  }


  /* Type Listing */
  _GetTypeList(ID: any) {
    return this.http.post(GET_TYPE_LIST, ID);
  }
  _INSERT_TYPE_MASTER(DATA: any) {
    return this.http.post(INSERT_TYPE_MASTER, DATA);
  }

  /* Transporter Delete */
  _getTransporterDelete(queryParam?: string) {
    let url = API_GET_TRANSPORTER_DELETE;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  _getAllPilantMines() {
    return this.http.get(`${API_GET_ALL_PLANT_MINES}?PlantID=${0}`);
  }
  _getDOListing(queryParam?: string) {
    let url = API_GET_ALL_DO_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }
  _getDOStatusChange(queryParam?: string) {
    let url = API_GET_STATUS_CHANGE;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }
  _saveDOData(mstData: any) {
    return this.http.post(API_POST_DO_SAVE, mstData);
  }

  _getDoCollectionType(queryParam?: string) {
    let url = API_DO_COLLECTION_TYPES;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  _getAllMinesMst(queryParam?: string) {
    let url = API_ALL_MINES_MASTER;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  _getPlantMineMappingDelete(queryParam?: string) {
    let url = API_GET_PLANT_MINE_DELETE;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  /* For New Version */
  _getAllLocation(queryParam?: string) {
    let url = API_GET_LOCATION_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }
  _saveLocationData(locationData: any) {
    return this.http.post(API_INSERT_UPDATE_LOCATION_DATA, locationData);
  }

  _getAllCustomer() {
    return this.http.get(API_GET_CUSTOMERS);
  }
  _getAllSamplingPoint() {
    return this.http.get(API_GET_ALL_SAMPLING_POINT);
  }
  /* Rfid Master Listing */
  _GetRfidMasterList(ID: any) {
    return this.http.post(API_GET_RFID_MASTER_LIST, ID);
  }
  _getUserWiseMinePlantMap(qString: any) {
    return this.http.post(API_GET_USER_WISE_MINE_PLANT_MAP, qString)
  }
  _getAllGrade() {
    return this.http.post(API_GET_ALL_GRADE, '')
  }
  _getSidingSilo(qString: any) {
    return this.http.post(API_GET_SIDING_SILO, qString)
  }
  _getSidingSource(qString: any) {
    return this.http.post(API_GET_SIDING_SOURCE, qString)
  }
  _getAllReceivingCustomer() {
    return this.http.get(API_GET_RECEIVING_CUSTOMERS)
  }

  _getAllReceivingCustomerUnloading() {
    return this.http.get(API_GET_RECEIVING_CUSTOMERS_UNLOADING)
  }

  _getUserWiseMineMap(qString: any) {
    return this.http.post(API_GET_USER_WISE_MINE_MAP, qString)
  }

  _getUserWiseMineMapUnloading(qString: any) {
    return this.http.post(API_GET_USER_WISE_MINE_PLANT_MAP_UNLOADING, qString)
  }

  __getUserWiseMinePlantMapUnloading(qString: any) {
    return this.http.post(API_GET_USER_WISE_MINE_MAP_UNLOADING, qString)
  }
  //Get All Coal Grade
  _getAllCoalGrade() {
    return this.http.post(GET_ALL_GRADE, {});
  }
  // Get mine By user
  _getAllMineMapByUser(param) {
    return this.http.post(GET_USER_WISE_MINE_MAP, param);
  }

  _getAllMineMapByUserResultSubmit() {
    return this.http.post(GET_USER_WISE_MINE_MAP_RESULT_SUBMIT, null);
  }

  _getResultForSubmit(params) {
    return this.http.post(API_GET_TEST_RESULT_FOR_SUBMIT, params);
  }

  _updateLabTestResult(params) {
    return this.http.post(API_UPDATE_LAB_TEST_RESULT, params);
  }

  _submitLabTestResult(params) {
    return this.http.post(API_SUBMIT_LAB_TEST_RESULT, params);
  }

  _getAllRefereeLisitng(qString) {
    return this.http.get(API_GET_REFFEREE_LISTING + qString)
  }

  _updatePaymentDetails(params) {
    return this.http.post(API_UPDATE_PAYMENT_DETAILS, params)
  }

  _getCompletedResult(params) {
    return this.http.get(API_GET_COMPLETED_TEST_RESULT + params)
  }

  _getRefereeResult(params) {
    return this.http.get(API_GET_REFEREE_RESULT + params)
  }

  _updateRefereeResult(params) {
    return this.http.post(API_UPDATE_REFEREE_RESULT, params)
  }

  downloadRefereeDoc(queryParam: any) {
    return this.http.get<any>(API_DOWNLOAD_REFEREE_DOC + queryParam);
  }

  _getAllThirdPartyLisitng(qString) {
    return this.http.get(API_GET_THIRD_PARTY_LAB_LISTING + qString)
  }

  _getAllThirdPartyLab() {
    return this.http.get(API_GET_ALL_THIRD_PARTY_LAB)
  }

  _sendToThirdPartyLab(params) {
    return this.http.post(API_SEND_TO_THIRD_PARTY_LAB, params)
  }

  _getAllRefereeUploadListing(qString) {
    return this.http.get(API_GET_REFREE_UPLOAD_LISTING + qString)
  }

  _generateSampleReport(params) {
    return this.http.post(API_GENERATE_SAMPLE_REPORT, params)
  }

  _downloadTestReport(qString) {
    const headers = new HttpHeaders({
      'Content-Type': 'text/plain',
    });
    headers.append('Content-Type', 'text/plain')
    return this.http.get(API_DOWNLOAD_TEST_REPORT + qString, { headers })
  }

  _downloadGCVReport(params) {
    return this.http.post(API_DOWNLOAD_GCV_REPORT, params)
  }

  _getAreaList() {
    return this.http.get(API_GET_AREA_LIST);
  }

  _getAreaTableList(qString) {
    return this.http.get(API_GET_AREA_TABLE_LISTING + qString);
  }

  _addAreaTableList(jsonData) {
    return this.http.post(API_GET_ADD_AREA_LIST, jsonData);
  }

  _getSidingTree() {
    return this.http.get(API_GET_SIDING_TREE);
  }

  _getSidingList(qString) {
    return this.http.get(API_GET_SIDING_TABLE_LIST + qString);
  }

  _addSiding(jsonData) {
    return this.http.post(API_GET_ADD_SIDING, jsonData);
  }

  _editSiding(qString) {
    return this.http.get(API_GET_SIDING_FOR_UPDATE + qString);
  }

  _getConsumerList(qString) {
    return this.http.get(API_GET_CONSUMER_LIST + qString);
  }

  _getConsumerTree() {
    return this.http.get(API_GET_CONSUMER_TREE);
  }

  _getMGRLoading() {
    return this.http.post(API_GET_MGR_LOADING, null);
  }
  _getGetArea() {
    return this.http.get(API_GET_AREA);
  }
  _getAllSidingMaster() {
    return this.http.get(API_GET_ALL_SIDING_MASTER);
  }
  _getConsumerTreeNode() {
    return this.http.get(API_GET_CONSUMER_TREE_NODE);
  }
  _getAllCategoryMaster() {
    return this.http.get(API_GET_ALL_CATEGORY_MASTER);
  }

  _InsertUpdateConsumerSubsidary(qString) {
    return this.http.post(API_INSERT_UPDATE_CONSUMER_SUBSIDARY, qString);
  }
  _GetCustomerDetailsById(qString) {
    return this.http.get(API_GET_CUSTOMER_DETAILS_BYID + qString);
  }
  _getConsumarList() {
    return this.http.get(API_GET_ALL_CUSTOMER_NAME_LIST);
  }

  _insertUpdateUserMaster(jsonData) {
    return this.http.post(API_INSERT_UPDATE_USER_MASTER, jsonData);
  }

  _getSubsidiaryProducer() {
    return this.http.get(API_GET_SUBSIDIARY_PRODUCER);
  }

  _getSubsidiaryList() {
    return this.http.get(API_GET_SUBSIDIARY_LIST);
  }

  _insertUpdateSubsidiary(jsonData) {
    return this.http.post(API_ADD_SUBSIDIARY, jsonData);
  }

  _getProducerList() {
    return this.http.get(API_GET_PRODUCER_LIST);
  }

  _insertUpdateProducer(jsonData) {
    return this.http.post(API_ADD_PRODUCER, jsonData);
  }

  _getSourceTreeNode() {
    return this.http.get(API_GET_SOURCE_TREENODE);
  }
  _getSourceList(jsonData) {
    return this.http.get(API_GET_SOURCE + jsonData);
  }
  _InsertUpdateSourceMaster(qString) {
    return this.http.post(API_INSER_UPDATE_SOURCE_MASTER, qString);
  }
  // DELETE MASTER DATA //
  _getAllSourceMasterListEditById(queryParam: string) {
    return this.http.get(API_GET_ALL_SOURCE_MASTER_LIST_EDIT_BYID + queryParam);
  }

  _insertUpdateOtherInfo(jsonData) {
    return this.http.post(API_UPDATE_OTHER_INFO, jsonData);
  }
  
  _getAllNotificationDetails() {
    return this.http.get(API_GET_ALL_NOTIFICATION_DETAILS)
  }
  _getAllSubsidary() {
    return this.http.post(API_GET_ALL_SUBSIDIARY,'')
  }
  _getAllReportForRejectionChallenge(queryParam: string) {
    return this.http.get(API_GET_ALL_IGI_REPORT_FOR_REJECTION_CHALLENGE + queryParam);
  }
  VVS_UPDATE_NOTIFICATION(queryParam:any){
    return this.http.post<any>(API_UPDATE_NOTIFICATION ,queryParam);
  }
}
