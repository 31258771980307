import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  message: string;
}

@Component({
  selector: 'app-excel-download-alert',
  templateUrl: './excel-download-alert.component.html',
  styleUrls: ['./excel-download-alert.component.scss']
})
export class ExcelDownloadAlertComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExcelDownloadAlertComponent>,) { }


  ngOnInit(): void {
  }

}
