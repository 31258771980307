<div class="app-header header top-header comb-header">
  <div class="container-fluid pl-0 bg-darkblue">
    <div class="d-flex">
      <!-- <a id="horizontal-navtoggle" class="animated-arrow hor-toggle"><span></span></a> -->
      <!-- sidebar-toggle-->
      <a class="header-brand">
        <img src="assets/img/igi-coal-logo.png" class="header-brand-img desktop-lgo"
          alt="Laboratory Information Management System">
      </a>
      <!-- <div class="dropdown   side-nav">
        <h6 class="sec-one">Laboratory Information Management System</h6>
        <a aria-label="Hide Sidebar" class="app-sidebar__toggle nav-link icon mt-1" data-toggle="sidebar" href="#"> <i class="fe fe-align-left"></i> </a>
      </div> -->

      <div class="ml-auto admin-logo">
        <img src="assets/img/bv-lims-logo-admin.png" alt="">
      </div>

      <!-- <div class="dropdown header-option">
        <form [formGroup]="formData">
          <select class="ag-form-control" (change)="setPlant($event.target.value)" formControlName="PlantID" disabled="true">
            <option *ngFor="let plant of plantList" [value]="plant.PlantID"> {{plant.PlantNameAlias}}
            </option>
          </select>
        </form>
      </div> -->

      <!-- <div class="dropdown   header-setting">
                <a class="nav-link icon" data-toggle="dropdown"> <i class="fe fe-settings"></i><span class="nav-span">Settings <i class="fa fa-angle-down ml-1 fs-18"></i></span> </a>
                <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow"> <a class="dropdown-item" href="#"> Multi Pages </a> <a class="dropdown-item" href="#"> Mail Settings </a> <a class="dropdown-item" href="#"> Default Settings </a> <a class="dropdown-item" href="#"> Documentation </a>
                    <div class="text-left pr-5 pl-5 p-2  border-top"> <a href="#" class="">Updated</a> </div>
                </div>
            </div> -->
      <div class="m-4">
        <div class="search-element text-white sys-admin"> Welcome {{ user?.Name }} </div>
      </div>
      <!-- <button mat-button [matMenuTriggerFor]="menu" xPosition="before">Menu</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
      </mat-menu> -->
      <!-- <span class="ag-notification" [matMenuTriggerFor]="beforeMenu">
        <span class="material-symbols-outlined">
          notifications
        </span>
      </span>
      <mat-menu #beforeMenu="matMenu" xPosition="before" class="p-0 m-0">
        <div class="ag-menu-list">
          <mat-list>
              <div class="ag-menu-list-content">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s
              </div>
              <div class="ag-menu-list-content">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s
              </div>
              <div class="ag-menu-list-content">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s
              </div>
          </mat-list>
        </div>
      </mat-menu> -->
      
      <div class="m-1">
        <span class="ag-notification" [matMenuTriggerFor]="beforeMenu">
          <span class="material-symbols-outlined">
            notifications
          </span>
          <span class="notification-count" *ngIf="InactiveCount > 0">
            {{InactiveCount}}
          </span>
        </span>
        <mat-menu #beforeMenu="matMenu" xPosition="before" class="p-0 m-0">
          <div class="ag-menu-list">
            <mat-list>
              <div class="ag-menu-list-content" *ngFor="let item of NotificationList"
                [ngStyle]="{'background-color': item.IsActive == 1? '#80d4ff22' : '#80d4ff71'}"
                (click)="openMessage(item)">
                <div class="notification-content">{{item?.NotificationMessage}}</div>
                <div class="notification-unseen" [ngStyle]="{'visibility': item.IsActive == 1? 'hidden' : 'visible'}">
                  <span></span>
                </div>
              </div>
            </mat-list>
          </div>
        </mat-menu>
      </div>

      <div class="dropdown header-notify">
        <a class="nav-link icon" (click)="logout()" aria-expanded="false"> <i class="mdi mdi-logout-variant"></i></a>
      </div>
      <!-- <div class="d-flex order-lg-2">
                <div class="dropdown ">
                    <a href="#" class="nav-link pr-0 leading-none" data-toggle="dropdown"> <span> <i class="mdi mdi-logout-variant"></i> </span> </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow ">
                        <div class="text-center"> <a href="#" class="dropdown-item text-center user pb-0">John Thomson</a> <span class="text-center user-semi-title text-dark">App Developer</span>
                            <div class="dropdown-divider"></div>
                        </div>
                        <a class="dropdown-item" href="#"> <i class="dropdown-icon mdi mdi-account-outline "></i> Profile </a>
                        <a class="dropdown-item" href="#"> <i class="dropdown-icon  mdi mdi-settings"></i> Settings </a>
                        <a class="dropdown-item" href="#"> <i class="dropdown-icon mdi  mdi-message-outline"></i> Inbox </a>
                        <a class="dropdown-item" href="#"> <i class="dropdown-icon mdi mdi-comment-check-outline"></i> Message </a>
                        <a class="dropdown-item" href="login.html"> <i class="dropdown-icon mdi  mdi-logout-variant"></i> Sign out </a>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</div>